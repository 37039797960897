@import "../../styles/variables";

.table{
}

.row{
    display: flex;
}

.jahr{
    min-width: 5rem;
    padding-right: .25rem;
}

.inhalt{
}