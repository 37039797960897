@import "../../styles/variables";

.intro{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    user-select: none;
    cursor: default;

    @include type-large;
    font-size: 12vw;
    @include tablet-down{
        font-size: 12vw;
    }
    line-height: 1em;
    color: $lightblue;
    background-color: $gray;
}