@import "../../styles/variables";

.stage {
  display: block;
}

.intro {
  position: fixed;
  z-index: $layer-10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.nav-btn {
  position: fixed;
  z-index: $layer-9;
  top: 1rem;
  left: 1rem;
}

.nav-body {
  position: fixed;
  z-index: $layer-8;
  top: 5.5rem;
  left: 0;
  @include tablet-down {
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.about {
  position: absolute;
  z-index: $layer-6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.info-btn {
  @include type-large;
  position: fixed;
  z-index: $layer-5;
  right: 0;
  @include tablet-up {
    top: 66.666%;
  }
  @include tablet-down {
    bottom: 0;
    display: flex;
    padding: 1rem;
  }
}

.interaction {
  position: fixed;
  z-index: $layer-4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slide {
  position: fixed;
  z-index: $layer-3;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
}