@import "../../styles/variables";

.container {
  position: relative;
  z-index: 301;
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 6rem;
  padding-bottom: 4rem;
  user-select: text;
  cursor: default;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

@include tablet-up {

  .container {
    display: flex;
  }

  .kontakt {
    width: 50%;
  }
  .cv {
    width: 100%;
  }
}

@include tablet-down {
}

.block {
  padding: 1rem;
  margin-bottom: 2rem;

  h3 {
    padding-bottom: 1rem;
  }

  img {
    display: block;
    max-width: 300px;
  }
}

