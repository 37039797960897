@import "../../styles/variables";

.copyright{
  @include type-large;
  @include type-large;
  font-size: 7vw;
  @include tablet-down{
    font-size: 7vw;
  }
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 1em;
  color: $lightblue;
  background-color: $gray;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}