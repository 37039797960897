@import "../../styles/variables";


.bright{
  transition: color $transition-1000;
  color: $white;
  :global{
    a{
      transition: color $transition-1000;
      color: $white;
      &:hover{
        color: $lightblue;
      }
    }
    a.active{
      color: $lightblue;
    }
  }
}





.dark{
  color: $darkgray;
  transition: color $transition-1000;

  :global{
    a{
      transition: color $transition-1000;
      color: $darkgray;
      &:hover{
        color: $black;
      }
    }
    a.active{
      color: $black;
    }
  }
}