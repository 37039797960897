@import "../../styles/variables";


.body {
  transition: background-color $transition-1000;
}

@include desktop {
  .body {
    padding: 0 2rem ;
    background: transparent !important;
  }
}

@include maxi {
  .body {
    background: transparent !important;
  }
}

@include tablet-down {
  .body {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}