@import "../../styles/variables";

.wrapper{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.prev{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    cursor: w-resize;
}

.next{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    cursor: e-resize;
}