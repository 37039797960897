@import "../../styles/variables";

.wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    //background-color: $lightblue;
}

.wrapperLoaded{
    background-color: transparent;
}

.image{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.loaded{
    opacity: 1 !important;
}

.withTransition{
    transition: background-color $transition-1000, opacity $transition-1000;
}