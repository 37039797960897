@import "../../styles/variables";

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 100ms linear;
  // remove the negative padding from the slide stage
  @include tablet-down {
    padding: 2rem;
  }
}

.container.active {
  opacity: 1;
}

.inner {
  position: relative;
  height: 100%;
  width: 100%;
}

.stage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 33.333%;
  overflow: hidden;
  @include type-large;
  @include tablet-up {
    text-align: right;
    padding-left: 2rem;
    padding-right: 6rem;
  }
  @include tablet-down {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }
}