@import "../../styles/variables";

.container{
}

.button{
  width: 4.5rem;
  height: 3.5rem;
  cursor: pointer;
}
