@import "./reset.scss";
@import "./variables";

html {
    font-size: 16px;
    background: $gray;
    @include type-normal;
    transition: background-color $transition-1000;
}

body {
    margin: 0;
    @include type-normal;
    transition: background-color $transition-1000;
    user-select: none;
    overflow-y: scroll;
}

.fixed{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}