@import "../../styles/variables";

.button, .button:focus, .button:active, .button:hover {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  background: none;
  padding: 1rem;
  border: none;
  outline: none;
  color: inherit;
}

.svg {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.line {
  stroke-width: 3px;
  vector-effect: non-scaling-stroke;
  transition: 150ms ease;
  transform-box: fill-box;
  transform-origin: center center;
  stroke: currentColor;
  stroke-linecap: butt;
}

@include tablet-up {
  .button:hover.active .top {
    transform: translateY(90px) rotate(15deg);
  }
}

.active .line.top {
  transform: translateY(90px) rotate(30deg);
}

.active .line.center {
  transform: scaleX(0);
}

@include tablet-up {
  .button:hover.active .bottom {
    transform: translateY(-90px) rotate(-15deg);
  }
}

.active .line.bottom {
  transform: translateY(-90px) rotate(-30deg);
}
