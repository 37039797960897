@import "../../styles/variables";

.stage{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: visible;
}

.inner{
    transition: width 300ms ease-out, height 300ms ease-out;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}