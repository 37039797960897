@import "../../styles/variables";

.wrapper{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include mobile{
        top: -2rem;
        right: -2rem;
        bottom: -2rem;
        left: -2rem;
    }
    opacity: 0;
    transform: translateZ(0.0002px);
    transition: opacity $transition-1000;
}

.isActive{
    opacity: 1;
}

.container{
    width: 100%;
    height: 100%;
    position: relative;
}