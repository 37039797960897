@import "../../styles/variables";

.link {
  @include type-large;
  @include tablet-down{
  font-size: 8vw!important;
  }
  display: flex;
  text-decoration: none;
  transition: color 200ms linear;
}