@mixin type-normal {
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  font-weight: normal;
}

@mixin type-large {
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  letter-spacing: 0.04em;
  line-height: 1.25em;
  @include tablet-down{
    font-size: 1.5rem;
  }
}
