@import "./variables";



.fade-1000-enter {
    opacity: 0.01;
}
.fade-1000-enter-active {
    opacity: 1;
    transition: opacity $transition-1000;
}
.fade-1000-exit {
    opacity: 1;
}
.fade-1000-exit-active {
    opacity: 0.01;
    transition: opacity $transition-1000;
}
.fade-1000-appear {
    opacity: 0.01;
}
.fade-1000-appear-active {
    opacity: 1;
    transition: opacity $transition-1000;
}




.fade-500-enter {
    opacity: 0;
}
.fade-500-enter-active {
    opacity: 1;
    transition: opacity 500ms linear;
}
.fade-500-exit {
    opacity: 1;
}
.fade-500-exit-active {
    opacity: 0;
    transition: opacity 500ms linear;
}